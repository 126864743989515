import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.config.resetDialog)?_c(VDialog,{attrs:{"max-width":"1150","scrollable":""},on:{"click:outside":_vm.closeDialog},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"primary--text",attrs:{"text":""},domProps:{"textContent":_vm._s(_vm.endpoint.name)},on:{"click":_vm.loadEndpoint}},'span',attrs,false),on))]}}],null,false,623571142),model:{value:(_vm.config.showDialog),callback:function ($$v) {_vm.$set(_vm.config, "showDialog", $$v)},expression:"config.showDialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,{staticClass:"heading grey--text text--darken-4"},[_vm._v("close")])],1)],1),_c(VCardText,{staticClass:"pl-6 pr-6"},[_c('EndpointDetail',{attrs:{"endpoint":_vm.endpointFull}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }