<template>
    <v-main>
        <v-container fluid style="max-width: 1400px">
            <EndpointDetail
                :endpoint="endpoint"
                :notFound="notFound"
            />
        </v-container>
    </v-main>
</template>
<script>
import axios from 'axios';
import { ref, computed, watch, onMounted } from '@vue/composition-api';
import EndpointDetail from '@/components/it/inventory/EndpointDetail';

export default {
    name: 'ViewEndpoint',
    components: {
        EndpointDetail
    },
    setup(_, { root }){
        const endpointId = computed(() => root.$route.params.endpointId);
        const endpoint = ref(null);
        const notFound = ref(false);
        


        const loadEndpoint = async () => {
            notFound.value = false;
            try {
                const response = await axios.get(`/inventory/endpoint_full_detail/${endpointId.value}/`);
                endpoint.value = response.data.endpoint;
            } catch (err) {
                // err.response.status === 404
                notFound.value = true;
            }
        };


        watch(endpointId, loadEndpoint);
        onMounted(loadEndpoint);

        return {
            endpoint,
            notFound,
            loadEndpoint,
        }
    }
};
</script>