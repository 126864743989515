<template>
    <v-dialog
        v-if="!config.resetDialog"
        v-model="config.showDialog"
        max-width="1150"
        @click:outside="closeDialog"
        scrollable
    >
        <template v-slot:activator="{ on, attrs }">
            <span
                class="primary--text"
                v-bind="attrs"
                v-on="on"
                v-text="endpoint.name"
                @click="loadEndpoint"
                text
            />
        </template>
        <v-card>
            <v-toolbar
                dense
                flat
            >
                <v-spacer />
                <v-btn
                    @click="closeDialog"
                    icon
                >
                    <v-icon class="heading grey--text text--darken-4">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="pl-6 pr-6">
                <EndpointDetail
                    :endpoint="endpointFull"
                />
            </v-card-text>
        </v-card>
        <!-- 
            <router-link
                :to="{name: 'it/inventory/endpoint', params: {endpointId: item.pk}}"
                v-text="item.__str__"
            />
        -->
    </v-dialog>
</template>
<script>
import axios from 'axios';
import { ref, reactive } from '@vue/composition-api';

export default {
    name: 'EndpointDialog',
    components: {
        EndpointDetail: () => import('@/components/it/inventory/EndpointDetail')
    },
    props: {
        endpoint: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    setup(props, { root }){

        const config = reactive({
            showDialog: false,
            resetDialog: false,
        });

        const endpointFull = ref(null);

        const closeDialog = () => {
            config.showDialog = false;
            config.resetDialog = true;

            root.$nextTick(() => {
                config.resetDialog = false;
            });
        };


        const loadEndpoint = async () => {
            const endpointId = props.endpoint.pk || props.endpoint.name;
            const response = await axios.get(`/inventory/endpoint_full_detail/${endpointId}/`);
            endpointFull.value = response.data.endpoint;
        };


        return {
            config,
            closeDialog,
            loadEndpoint,
            endpointFull
        }
    }
};
</script>